import React, { useState, useEffect } from "react";
import { signUp } from "../../auth";
import AuthModal from "../../components/Auth/AuthModal";
import "../Auth/Auth.css";

const SignUp = () => {
  const [fullName, setFullName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [error, setError] = useState("");
  const [showAuthModal, setShowAuthModal] = useState(false);

  const blacklistedDomains = [
    "gmail.com",
    "yahoo.com",
    "yahoo.co.in",
    "msn.com",
    "hotmail.com",
    "wci.com",
    "wcico.com",
    "acuitybrands.com",
    "opulentamericas.com",
    "ams-osram.com",
    "forzlux.com",
    "futurelightingsolutions.com",
    "FutureElectronics.com",
    "wpgamericas.com",
    "wpgacorp.com",
    "lumileds.com",
    "new-energyllc.com",
    "newenergyllc.com",
    "LTFtechnology.com",
    "ltftechnology.com",
    "keystonetech.com",
    "signify.com",
    "metrospectech.com",
    "flexrad.com",
    "eulum.lighting",
    "lumasmart.com",
    "seoulsemicon.com",
    "samsung.com",
    "arkalumen.com",
    "aceleds.com",
    "tridonic.com",
    "eldoled.com",
    "luxtech.com",
    "fulham.com",
    "xicato.com",
    "bridgelux.com",
    "sbcglobal.net",
    "rhyta.com",
  ];

  useEffect(() => {
    setPasswordStrength(calculatePasswordStrength(password));
  }, [password]);

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError("");
    if (!validateEmail(companyEmail)) {
      setError("Invalid company email.");
      return;
    }
    if (!validatePassword(password)) {
      setError(
        "Password must be at least 8 characters with lowercase, uppercase, number, and special character."
      );
      return;
    }

    try {
      await signUp(fullName, companyName, companyEmail, password);
      setShowAuthModal(true);
    } catch (error) {
      setError("Error signing up: " + error.message);
    }
  };

  const validateEmail = (email) => {
    const companyEmailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const domain = email.split("@")[1];
    if (!companyEmailPattern.test(email)) {
      return false;
    }
    if (blacklistedDomains.includes(domain)) {
      setError(
        "Sorry. The email domain you entered is not supported at this time."
      );
      return false;
    }
    return true;
  };

  const validatePassword = (password) => {
    const passwordPattern =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    return passwordPattern.test(password);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
  };

  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 6) strength++;
    if (password.length >= 8) strength++;
    if (/[A-Z]/.test(password)) strength++;
    if (/\d/.test(password)) strength++;
    if (/[@$!%*?&]/.test(password)) strength++;
    return strength;
  };

  const getPasswordStrengthLabel = (strength) => {
    switch (strength) {
      case 1:
        return "Weak";
      case 2:
      case 3:
        return "Moderate";
      case 4:
      case 5:
        return "Strong";
      default:
        return "";
    }
  };

  const getPasswordStrengthColor = (strength) => {
    switch (strength) {
      case 1:
        return "red";
      case 2:
      case 3:
        return "orange";
      case 4:
      case 5:
        return "green";
      default:
        return "";
    }
  };

  return (
    <div className="auth-container">
      <h2 className="auth-container-title">Create an Account</h2>
      <form onSubmit={handleSignUp} className="signup-form">
        <div>
          <label>
            Full Name:
            <input
              type="text"
              placeholder="e.g., John Doe"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Company Name:
            <input
              type="text"
              placeholder="e.g., Acme Corp"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Company Email:
            <input
              type="email"
              placeholder="e.g., john.doe@acme.com"
              value={companyEmail}
              onChange={(e) => setCompanyEmail(e.target.value)}
              required
            />
          </label>
        </div>
        <div className="password-input-container">
          <label>Password:</label>
          <input
            type="password"
            placeholder="Create a strong password"
            value={password}
            onChange={handlePasswordChange}
            required
          />

          <div className="password-strength-container">
            <span
              className="password-strength-label"
              style={{
                color: getPasswordStrengthColor(passwordStrength),
              }}
            >
              {getPasswordStrengthLabel(passwordStrength)}
            </span>
            <div className="password-strength-meter">
              {Array.from({ length: 5 }).map((_, index) => (
                <div
                  key={index}
                  className={`password-strength-slot ${
                    passwordStrength > index ? "filled" : ""
                  }`}
                  style={{
                    backgroundColor:
                      passwordStrength > index
                        ? getPasswordStrengthColor(passwordStrength)
                        : "#e0e0e0",
                  }}
                ></div>
              ))}
            </div>
          </div>
        </div>
        {error && <div className="error-message">{error}</div>}
        <button type="submit">Sign Up</button>
      </form>
      <div className="login-redirect">
        Already a user? <a href="/login">Login</a>
      </div>
      {showAuthModal && (
        <AuthModal
          onClose={() => setShowAuthModal(false)}
          message="Please check your email to verify your account."
        />
      )}
    </div>
  );
};

export default SignUp;
