// frontend/src/pages/Header.jsx
import React, { useState } from "react";
import "./Header.css";
import logo from "../../assets/images/logo.png";
import { useAuth } from "../../AuthContext";

const Header = ({ onSignOut }) => {
  const { user, handleSignOut } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="logo-container">
        <a href="https://adurasolutions.com">
          <img src={logo} alt="logo" className="header-logo" />
        </a>
        <a href="/" className="rajdhani-bold">
          LSC
        </a>
      </div>

      <nav className="nav-container">
        <div className="nav-links-wrapper">
          <ul className="nav-links">
            <li className="nav-items">
              <a href="/pcb-selection">PCB Selector</a>
            </li>
            <li className="nav-items">
              <a href="/module-calculator">LED Module Calculator</a>
            </li>
            <li className="nav-items">
              <a href="/create">System Creator</a>
            </li>
            <li className="nav-items">
              <a href="/your-creations">Your Creations</a>
            </li>
            {user && user.userType === "admin" && (
              <li className="nav-items">
                <a href="/browse-collection">Browse Collection</a>
              </li>
            )}
          </ul>
          <div className="auth-buttons">
            {user ? (
              <>
                <span className="greeting">
                  Hello,{" "}
                  {user.full_name ? user.full_name.split(" ")[0] : "User"}
                </span>
                <button className="sign-out-button" onClick={handleSignOut}>
                  SIGN OUT
                </button>
              </>
            ) : (
              <>
                <a href="/login" className="login-button">
                  LOG IN
                </a>
                <a href="/create-account" className="create-account-button">
                  CREATE AN ACCOUNT
                </a>
              </>
            )}
          </div>
        </div>
        <button
          className={`hamburger-menu ${isMenuOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div className={`sidebar ${isMenuOpen ? "open" : ""}`}>
          <ul className="nav-links">
          <li className="nav-items">
              <a href="/pcb-selection">PCB Selector</a>
            </li>
            <li className="nav-items">
              <a href="/module-calculator">LED Module Calculator</a>
            </li>
            <li className="nav-items">
              <a href="/create">System Creator</a>
            </li>
            <li className="nav-items">
              <a href="/your-creations">Your Creations</a>
            </li>
            {user && user.userType === "admin" && (
              <li className="nav-items">
                <a href="/browse-collection">Browse Collection</a>
              </li>
            )}
          </ul>
          <div className="auth-buttons">
            {user ? (
              <>
                <span className="greeting">
                  Hello,{" "}
                  {user.full_name ? user.full_name.split(" ")[0] : "User"}
                </span>
                <button className="sign-out-button" onClick={handleSignOut}>
                  Sign Out
                </button>
              </>
            ) : (
              <>
                <a href="/login" className="login-button">
                  LOG IN
                </a>
                <a href="/create-account" className="create-account-button">
                  CREATE AN ACCOUNT
                </a>
              </>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
