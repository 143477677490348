// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pcb-tab {
    display: inline;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 10.8125rem;
    height: 5.5rem;
    margin: 2em 1em;
}
.tabs-action-btn {
    margin: 0 1em;
    display: inline;
   
}
.pcb-tab.active{
    background-color: #228b22 !important;
    color: #f5f5f5;
    box-shadow: none;
}
.pcb-tab:not(.active) {
    background-color: #f5f5f5 !important;
    color: #333;
    box-shadow: none;
}
.pcb-tab:not(.active):hover {
    background-color: #f5f5f5 !important;
    color: #333;
    box-shadow: 0em 0.25em 0.375em rgba(34,13,34,0.3);
}
.pcb-icon {
    width: 4em;
    height: auto;
}
.pcba-icon {
    width: 4em;
    height: auto;
}
.pcb-text {
    font-family: "Fivo Sans Medium", sans-serif;
    font-size: 1rem;
    margin-top: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/PCBSelection/PCBSelection.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,cAAc;IACd,eAAe;AACnB;AACA;IACI,aAAa;IACb,eAAe;;AAEnB;AACA;IACI,oCAAoC;IACpC,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,oCAAoC;IACpC,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,oCAAoC;IACpC,WAAW;IACX,iDAAiD;AACrD;AACA;IACI,UAAU;IACV,YAAY;AAChB;AACA;IACI,UAAU;IACV,YAAY;AAChB;AACA;IACI,2CAA2C;IAC3C,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".pcb-tab {\n    display: inline;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 10.8125rem;\n    height: 5.5rem;\n    margin: 2em 1em;\n}\n.tabs-action-btn {\n    margin: 0 1em;\n    display: inline;\n   \n}\n.pcb-tab.active{\n    background-color: #228b22 !important;\n    color: #f5f5f5;\n    box-shadow: none;\n}\n.pcb-tab:not(.active) {\n    background-color: #f5f5f5 !important;\n    color: #333;\n    box-shadow: none;\n}\n.pcb-tab:not(.active):hover {\n    background-color: #f5f5f5 !important;\n    color: #333;\n    box-shadow: 0em 0.25em 0.375em rgba(34,13,34,0.3);\n}\n.pcb-icon {\n    width: 4em;\n    height: auto;\n}\n.pcba-icon {\n    width: 4em;\n    height: auto;\n}\n.pcb-text {\n    font-family: \"Fivo Sans Medium\", sans-serif;\n    font-size: 1rem;\n    margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
