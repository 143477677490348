import React, { useState } from "react";
import AdvancedPCBForm from "../../components/PCBSelection/AdvancedPCBForm.js";
import PCBAssemblyForm from "../../components/PCBSelection/PCBAssemblyForm.js";
import "./PCBSelection.css";
const PCBSelection = () => {
    const [activeTab, setActiveTab] = useState("advanced");
    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = (section) => {
        setExpanded((prev) => ({ ...prev, [section]: !prev[section] }));
    };

    return (
        <div className="system-creator">
            <div className="pcb-tabs">
                <div className="tabs-action-btn"> <button
                    onClick={() => setActiveTab("advanced")} className={`pcb-tab ${activeTab === "advanced" ? "active" : ""}`}>
                    <img src="/images/PCB.webp" alt="Advanced PCB" className="pcb-icon" />
                    <p className="pcb-text">Advanced PCB</p>

                </button>
                    <button
                        onClick={() => setActiveTab("assembly")} className={`pcb-tab ${activeTab === "assembly" ? "active" : ""}`}>
                        <img src="/images/assembly.webp" alt="Assembly PCB" className="pcba-icon" />
                        <p className="pcb-text">PCB Assembly</p>
                    </button></div>

            </div>
            <div className="pcb-form-container">
                {activeTab === "advanced" ? (
                    <AdvancedPCBForm expanded={expanded} toggleExpanded={toggleExpanded} />
                ) : (
                    <PCBAssemblyForm expanded={expanded} toggleExpanded={toggleExpanded} />
                )}
            </div>
        </div>
    );
};
export default PCBSelection;