import { useState } from "react";
import "./PCBAssemblyForm.css";

export const PCBAssemblyForm = ({ isInsideAdvancedPCBForm = false }) => {
    const [expanded, setExpanded] = useState(false);
    const [formData, setFormData] = useState({
        assemblyQuantity: "",
        dimensions: {
            width: "",
            height: "",
        },
        depanelization: "No",
        bom: "Adura Supply",
        substitution: "No",
        masking: "No",
        sensitive: "No",
        coating: "No",
        service: [],
        remark: "",
    });

    const handleSelect = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

    const handleMultiSelect = (name, value) => {
        setFormData((prev) => ({
            ...prev,
            [name]: prev[name].includes(value)
                ? prev[name].filter((item) => item !== value)
                : [...prev[name], value],
        }));
    };


    return (
        <div className="pcb-assembly-form">
            <h2 className="form-title">
                PCB Assembly
            </h2>
            <table className="pcb-table">
                <tbody>
                    <tr>
                        <td>PCB Assembly Quantity
                            <span className="info-tooltip">
                                <span className="tooltip-icon">ℹ</span>
                                <span className="tooltip-text">Select how many PCBs you need to assemble.</span>
                            </span>
                        </td>
                        <td>
                            <input type="number" className={"input-pcb"} value={formData.assemblyQuantity} onChange={(e) => handleSelect("assemblyQuantity", e.target.value)} />
                            {" "} pcs
                        </td>
                    </tr>
                    <tr>
                        <td>PCB Dimension (mm)
                            <span className="info-tooltip">
                                <span className="tooltip-icon">ℹ</span>
                                <span className="tooltip-text">Enter the width and height of the PCB.</span>
                            </span>
                        </td>
                        <td>
                            <input type="text" placeholder="Width" className={"input-pcb"} value={formData.dimensions.width} onChange={(e) => handleSelect("dimensions", { ...formData.dimensions, width: e.target.value })} />
                            {" x "}
                            <input type="text" placeholder="Height" className={"input-pcb"} value={formData.dimensions.height} onChange={(e) => handleSelect("dimensions", { ...formData.dimensions, height: e.target.value })} />
                        </td>
                    </tr>
                    <tr>
                        <td>Depanel the PCBs
                            <span className="info-tooltip">
                                <span className="tooltip-icon">ℹ</span>
                                <span className="tooltip-text">Select whether you need the PCBs to be depaneled.</span>
                            </span></td>
                        <td>
                            {["No", "Yes"].map((depanel) => (
                                <button key={depanel} className={`pcb-button ${formData.depanelization === depanel ? "selected" : ""}`} onClick={() => handleSelect("depanelization", depanel)}>
                                    {depanel}
                                </button>
                            ))}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="optional-parameters">
                <table>

                    <tbody>
                        <tr>
                            <td>Assembly Side
                                <span className="info-tooltip">
                                    <span className="tooltip-icon">ℹ</span>
                                    <span className="tooltip-text">Select whether the PCB is single or double sided.</span>
                                </span></td>
                            <td>Total Number of Components
                                <span className="info-tooltip">
                                    <span className="tooltip-icon">ℹ</span>
                                    <span className="tooltip-text">Enter the total number of SMD and Through-Hole components.</span>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <select>
                                    <option>Single Sided</option>
                                    <option>Double Sided</option>
                                </select>
                            </td>
                            <td>
                                <input type="number" placeholder="Total Number" />
                            </td>
                        </tr>
                        <tr>
                            <td># of SMD Components
                                <span className="info-tooltip">
                                    <span className="tooltip-icon">ℹ</span>
                                    <span className="tooltip-text">Enter the total number of SMD components.</span>
                                </span>
                            </td>
                            <td># of Through-Hole Components
                                <span className="info-tooltip">
                                    <span className="tooltip-icon">ℹ</span>
                                    <span className="tooltip-text">Enter the total number of Through-Hole components.</span>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="number" placeholder="Total Number" />
                            </td>
                            <td>
                                <input type="number" placeholder="Total Number" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {!isInsideAdvancedPCBForm && (
                <div className="expand-section">
                    <h3>Advanced Options</h3>
                    <button onClick={() => setExpanded(!expanded)} className="expand-button">
                        {expanded ? "Expand ▼" : "Collapse ▲"}
                    </button>
                </div>)}

            {(!expanded || isInsideAdvancedPCBForm) && (
                <div className="advanced-options">
                    <table className="pcb-table">
                        <tbody>
                            <tr>
                                <td>PCBA Application
                                    <span className="info-tooltip">
                                        <span className="tooltip-icon">ℹ</span>
                                        <span className="tooltip-text">Select the application of the PCB Assembly.</span>
                                    </span>
                                </td>
                                <td><select>
                                    <option>Consumer Electronics</option>
                                    <option>Medical Devices</option>
                                    <option>Automotive</option>
                                    <option>Industrial</option>
                                    <option>Mechanical</option>
                                </select></td>

                            </tr>
                            <tr>
                                <td>BOM Parts Options
                                    <span className="info-tooltip">
                                        <span className="tooltip-icon">ℹ</span>
                                        <span className="tooltip-text">Select the BOM parts options.</span>
                                    </span>
                                </td>
                                <td>{["Adura Supply", "Customer Supply", "Combo Solution"].map((bom_option) => (
                                    <button key={bom_option} className={`pcb-button ${formData.bom === bom_option ? "selected" : ""}`} onClick={() => handleSelect("bom", bom_option)}>
                                        {bom_option}
                                    </button>
                                ))}</td>

                            </tr>
                            <tr>
                                <td>Allow Substitutions
                                    <span className="info-tooltip">
                                        <span className="tooltip-icon">ℹ</span>
                                        <span className="tooltip-text">Select whether substitutions are allowed.</span>
                                    </span>
                                </td>
                                <td>{["No", "Yes"].map((substitution) => (
                                    <button key={substitution} className={`pcb-button ${formData.substitution === substitution ? "selected" : ""}`} onClick={() => handleSelect("substitution", substitution)}>
                                        {substitution}
                                    </button>
                                ))}</td>

                            </tr>
                            <tr>
                                <td>Contains Sensitive Components
                                    <span className="info-tooltip">
                                        <span className="tooltip-icon">ℹ</span>
                                        <span className="tooltip-text">Select whether the PCB contains sensitive components.</span>
                                    </span>
                                </td>
                                <td>{["No", "Yes"].map((sensitive) => (
                                    <button key={sensitive} className={`pcb-button ${formData.sensitive === sensitive ? "selected" : ""}`} onClick={() => handleSelect("sensitive", sensitive)}>
                                        {sensitive}
                                    </button>
                                ))}</td>
                            </tr>
                            <tr>
                                <td>Masking Required
                                    <span className="info-tooltip">
                                        <span className="tooltip-icon">ℹ</span>
                                        <span className="tooltip-text">Select whether masking is required.</span>
                                    </span>
                                </td>
                                <td>{["No", "Yes"].map((masking) => (
                                    <button key={masking} className={`pcb-button ${formData.masking === masking ? "selected" : ""}`} onClick={() => handleSelect("masking", masking)}>
                                        {masking}
                                    </button>
                                ))}</td>
                            </tr>
                            <tr>
                                <td>Conformal Coating
                                    <span className="info-tooltip">
                                        <span className="tooltip-icon">ℹ</span>
                                        <span className="tooltip-text">Select whether conformal coating is required.</span>
                                    </span>
                                </td>
                                <td>{["No", "Single Sided", "Double Sided"].map((coating) => (
                                    <button key={coating} className={`pcb-button ${formData.coating === coating ? "selected" : ""}`} onClick={() => handleSelect("coating", coating)}>
                                        {coating}
                                    </button>
                                ))}</td>
                            </tr>
                            <tr>
                                <td>Additional Services
                                    <span className="info-tooltip">
                                        <span className="tooltip-icon">ℹ</span>
                                        <span className="tooltip-text">Select any additional services required.</span>
                                    </span>
                                </td>
                                <td>{["X-Ray Test", "IC Programming", "Functional Test", "Add Solder-Paste", "Sample Picture Confirm"].map((service) => (
                                    <button key={service} className={`pcb-button ${formData.service.includes(service) ? "selected" : ""}`} onClick={() => handleMultiSelect("service", service)}>
                                        {service}
                                    </button>
                                ))}</td>
                            </tr>
                            <tr>
                                <td>Remarks
                                    <span className="info-tooltip">
                                        <span className="tooltip-icon">ℹ</span>
                                        <span className="tooltip-text">Fill in any PCBA requirements to help us better understand your needs.</span>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <textarea type="text" value={formData.remark} className="remark-input" placeholder="Fill in any PCBA requirements to help us better understand your needs." />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
            {!isInsideAdvancedPCBForm && <button className="submit-button">Quote Now</button>}
        </div>
    );
};

export default PCBAssemblyForm;
