import { useState } from "react";
import "./AdvancedPCBForm.css";
import { PCBAssemblyForm } from "../PCBSelection/PCBAssemblyForm";

const AdvancedPCBForm = () => {
    const [expanded, setExpanded] = useState(false);
    const [expanded2, setExpanded2] = useState(false);
    const [expandedFinish, setExpandedFinish] = useState(false);
    const [expandedSolderMask, setExpandedSolderMask] = useState(false);
    const [expandedSilkscreen, setExpandedSilkscreen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [showAssemblyForm, setShowAssemblyForm] = useState(false);
    const [formData, setFormData] = useState({
        materialType: "FR4",
        layers: "",
        width: "",
        height: "",
        quantity: "",
        thickness: "1.6mm",
        solderMask: "Green",
        silkscreen: "White",
        finish: "HASL",
        outerCopper: "1oz",
        minSpacing: "5/5mil",
        minHoleSize: "0.3mm",
        viaProcess: "Tenting Vias",
        castellatedHoles: "None",
        pthCopperThickness: "35µm",
        specialTechnique: [],
        testReport: [],
        remark: "",
    });

    const handleSelect = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

    const handleMultiSelect = (name, value) => {
        setFormData((prev) => ({
            ...prev,
            [name]: prev[name].includes(value)
                ? prev[name].filter((item) => item !== value)
                : [...prev[name], value],
        }));
    };

    const solderMaskColors = [
        { name: "Green", hex: "#008000" },
        { name: "White", hex: "#FFFFFF", border: "1px solid #ccc" },
        { name: "Blue", hex: "#0000FF" },
        { name: "Black", hex: "#000000" },
        { name: "Yellow", hex: "#FFFF00" },
        { name: "Red", hex: "#FF0000" },

        { name: "Matte Black", hex: "#333" },
        { name: "Matte Green", hex: "#46c016" }, { name: "None", hex: "#fff", border: "1px solid #ccc" }
    ]
    const silkscreenColors = [
        { name: "White", hex: "#FFFFFF", border: "1px solid #ccc" },
        { name: "Black", hex: "#000000" },
        { name: "Yellow", hex: "#FFFF00" },
        { name: "Green", hex: "#008000" },
        { name: "Blue", hex: "#0000FF" },
        { name: "Red", hex: "#FF0000" },
        { name: "None", hex: "#fff", border: "1px solid #ccc" },
    ]
    const surfaceFinish = [
        { name: "Lead Free HASL" },
        { name: "Tin-Lead HASL" },
        { name: "ENIG (Immersion Gold)" },
        { name: "OSP" },
        { name: "Immersion Tin" },
        { name: "Immersion Silver" },
        { name: "Gold Plating" },
        { name: "ENEPIG" },
        { name: "OSP+Selective ENIG" },
        { name: "Lead-free HASL Selective ENIG" },
        { name: "Lead-free HASL + Selective Gold Plating" },
    ]
    return (
        <div className="advanced-pcb-form">
            <h2>Configure Your PCB</h2>
            <table className="pcb-table">
                <tbody>
                    <tr>
                        <td>Material Type
                            <span className="info-tooltip">
                                <span className="tooltip-icon">ℹ</span>
                                <span className="tooltip-text">Select the base material for your PCB.</span>
                            </span>
                        </td>
                        <td>
                            {["FR4", "CEM-1", "Aluminum", "Copper Core", "Rogers"].map((material) => (
                                <button
                                    key={material}
                                    className={`pcb-button ${formData.materialType === material ? "selected" : ""}`}
                                    onClick={() => handleSelect("materialType", material)}
                                >
                                    {material}
                                </button>
                            ))}
                        </td>
                    </tr>
                    <tr>
                        <td>Layers
                            <span className="info-tooltip">
                                <span className="tooltip-icon">ℹ</span>
                                <span className="tooltip-text"> Select the number of layers for your PCB.</span>
                            </span>
                        </td>
                        <td>
                            <input
                                type="number" min="1" max="32"
                                value={formData.layers}
                                className={"input-pcb"}
                                onChange={(e) => handleSelect("layers", e.target.value)}>
                            </input>
                        </td>
                    </tr>
                    <tr>
                        <td>Dimensions (mm)
                            <span className="info-tooltip">
                                <span className="tooltip-icon">ℹ</span>
                                <span className="tooltip-text">Enter the width and height of your PCB in millimeters.</span>
                            </span>
                        </td>
                        <td>
                            <input
                                type="text"
                                placeholder="Width"
                                value={formData.width}
                                className="input-pcb"
                                onChange={(e) => handleSelect("width", e.target.value)}
                            />
                            {"  X  "}
                            <input
                                type="text"
                                placeholder="Height"
                                className="input-pcb"
                                value={formData.height}
                                onChange={(e) => handleSelect("height", e.target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Quantity
                            <span className="info-tooltip">
                                <span className="tooltip-icon">ℹ</span>
                                <span className="tooltip-text">Enter the number of PCBs you need.</span>
                            </span>
                        </td>
                        <td>
                            <input
                                type="number"
                                min="1"
                                className="input-pcb"
                                value={formData.quantity}
                                onChange={(e) => handleSelect("quantity", e.target.value)}
                            />
                            pcs
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className="expand-section">
                <h3>PCB Specifications</h3>
                <button className="expand-button" onClick={() => setExpanded(!expanded)}>
                    {expanded ? "Expand ▼" : "Collapse ▲"}
                </button>
            </div>

            {
                !expanded && (
                    <div className="advanced-options">
                        <table className="pcb-table">
                            <tbody>
                                <tr>
                                    <td>Thickness
                                        <span className="info-tooltip">
                                            <span className="tooltip-icon">ℹ</span>
                                            <span className="tooltip-text">Select the thickness of your PCB.</span>
                                        </span>
                                    </td>
                                    <td className="thickness-buttons">
                                        {["0.4mm", "0.6mm", "0.8mm", "1.0mm", "1.2mm", "1.6mm", "2.0mm", "3.0mm"].map((thickness) => (
                                            <button
                                                key={thickness}
                                                className={`pcb-button ${formData.thickness === thickness ? "selected" : ""}`}
                                                onClick={() => handleSelect("thickness", thickness)}
                                            >
                                                {thickness}
                                            </button>
                                        ))}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Solder Mask
                                        <span className="info-tooltip">
                                            <span className="tooltip-icon">ℹ</span>
                                            <span className="tooltip-text">Select the solder mask color for your PCB.</span>
                                        </span>
                                    </td>
                                    <td>
                                        <div className="surface-container">
                                            <div className="color-options">
                                                {solderMaskColors.slice(0, 5).map((color) => (
                                                    <button
                                                        key={color.name}
                                                        className={`pcb-button color-option ${formData.solderMask === color.name ? "selected" : ""}`}
                                                        onClick={() => handleSelect("solderMask", color.name)}
                                                    >
                                                        <span className="color-square" style={{ backgroundColor: color.hex, border: color.border || "none" }}>
                                                            {color.name === "None" && <span className="x-mark">❌</span>}
                                                        </span>
                                                        {color.name}
                                                    </button>
                                                ))}
                                            </div>

                                            {expandedSolderMask && (
                                                <div className="more-surface">
                                                    {solderMaskColors.slice(5).map((color) => (
                                                        <button
                                                            key={color.name}
                                                            className={`pcb-button color-option ${formData.solderMask === color.name ? "selected" : ""}`}
                                                            onClick={() => handleSelect("solderMask", color.name)}
                                                        >
                                                            <span className="color-square" style={{ backgroundColor: color.hex, border: color.border || "none" }}>
                                                                {color.name === "None" && <span className="x-mark">❌</span>}
                                                            </span>
                                                            {color.name}
                                                        </button>
                                                    ))}
                                                </div>
                                            )}

                                            <div className="more-options">
                                                <button className="pcb-button more-options-button" onClick={() => setExpandedSolderMask(!expandedSolderMask)}>
                                                    {expandedSolderMask ? "Hide ▲" : "More Options ▼"}
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Silkscreen
                                        <span className="info-tooltip">
                                            <span className="tooltip-icon">ℹ</span>
                                            <span className="tooltip-text">Select the silkscreen color for your PCB.</span>
                                        </span>
                                    </td>
                                    <td>
                                        <div className="surface-container">
                                            <div className="color-options">
                                                {silkscreenColors.slice(0, 5).map((color) => (
                                                    <button
                                                        key={color.name}
                                                        className={`pcb-button color-option ${formData.silkscreen === color.name ? "selected" : ""}`}
                                                        onClick={() => handleSelect("silkscreen", color.name)}
                                                    >
                                                        <span className="color-square" style={{ backgroundColor: color.hex, border: color.border || "none" }}>
                                                            {color.name === "None" && <span className="x-mark">❌</span>}
                                                        </span>
                                                        {color.name}
                                                    </button>
                                                ))}
                                            </div>

                                            {expandedSilkscreen && (
                                                <div className="more-surface">
                                                    {silkscreenColors.slice(5).map((color) => (
                                                        <button
                                                            key={color.name}
                                                            className={`pcb-button color-option ${formData.silkscreen === color.name ? "selected" : ""}`}
                                                            onClick={() => handleSelect("silkscreen", color.name)}
                                                        >
                                                            <span className="color-square" style={{ backgroundColor: color.hex, border: color.border || "none" }}>
                                                                {color.name === "None" && <span className="x-mark">❌</span>}
                                                            </span>
                                                            {color.name}
                                                        </button>
                                                    ))}
                                                </div>
                                            )}

                                            <div className="more-options">
                                                <button className="pcb-button more-options-button" onClick={() => setExpandedSilkscreen(!expandedSilkscreen)}>
                                                    {expandedSilkscreen ? "Hide ▲" : "More Options ▼"}
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Surface Finish
                                        <span className="info-tooltip">
                                            <span className="tooltip-icon">ℹ</span>
                                            <span className="tooltip-text">Select the surface finish for your PCB.</span>
                                        </span>
                                    </td>
                                    <td>
                                        <div className="surface-container">
                                            <div className="surface-buttons">
                                                {surfaceFinish.slice(0, 6).map((finish) => (
                                                    <button
                                                        key={finish.name}
                                                        className={`pcb-button ${formData.finish === finish.name ? "selected" : ""}`}
                                                        onClick={() => handleSelect("finish", finish.name)}
                                                    >
                                                        {finish.name}
                                                    </button>
                                                ))}
                                            </div>
                                            {expandedFinish && (
                                                <div className="more-surface">
                                                    {surfaceFinish.slice(6).map((finish) => (
                                                        <button
                                                            key={finish.name}
                                                            className={`pcb-button ${formData.finish === finish.name ? "selected" : ""}`}
                                                            onClick={() => handleSelect("finish", finish.name)}
                                                        >
                                                            {finish.name}
                                                        </button>
                                                    ))}
                                                </div>
                                            )}
                                            <div className="more-options">
                                                <button className="pcb-button more-options-button" onClick={() => setExpandedFinish(!expandedFinish)}>
                                                    {expandedFinish ? "Hide ▲" : "More Options ▼"}
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Outer Copper
                                        <span className="info-tooltip">
                                            <span className="tooltip-icon">ℹ</span>
                                            <span className="tooltip-text">Select the outer copper thickness for your PCB.</span>
                                        </span>
                                    </td>
                                    <td>
                                        {["1oz", "2oz", "3oz", "4oz", "6oz", "8oz", "10oz"].map((copper) => (
                                            <button
                                                key={copper}
                                                className={`pcb-button ${formData.outerCopper === copper ? "selected" : ""}`}
                                                onClick={() => handleSelect("outerCopper", copper)}
                                            >
                                                {copper}
                                            </button>
                                        ))}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Min Spacing
                                        <span className="info-tooltip">
                                            <span className="tooltip-icon">ℹ</span>
                                            <span className="tooltip-text">Select the minimum spacing between traces for your PCB.</span>
                                        </span>
                                    </td>
                                    <td>
                                        {["3/3mil", "4/4mil", "5/5mil", "6/6mil", "10/10mil", "20/20mil"].map((spacing) => (
                                            <button
                                                key={spacing}
                                                className={`pcb-button ${formData.minSpacing === spacing ? "selected" : ""}`}
                                                onClick={() => handleSelect("minSpacing", spacing)}
                                            >
                                                {spacing}
                                            </button>
                                        ))}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Min Hole Size
                                        <span className="info-tooltip">
                                            <span className="tooltip-icon">ℹ</span>
                                            <span className="tooltip-text">Select the minimum hole size for your PCB.</span>
                                        </span>
                                    </td>
                                    <td>
                                        {["No Hole", "0.2mm", "0.25mm", "0.3mm", "0.4mm", "0.5mm"].map((hole) => (
                                            <button
                                                key={hole}
                                                className={`pcb-button ${formData.minHoleSize === hole ? "selected" : ""}`}
                                                onClick={() => handleSelect("minHoleSize", hole)}
                                            >
                                                {hole}
                                            </button>
                                        ))}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Via Process
                                        <span className="info-tooltip">
                                            <span className="tooltip-icon">ℹ</span>
                                            <span className="tooltip-text">Select the via process for your PCB.</span>
                                        </span>
                                    </td>
                                    <td>
                                        {["Tenting Vias", "Vias Covered", "Resin Plug-hole", "Plugged Vias"].map((via) => (
                                            <button
                                                key={via}
                                                className={`pcb-button ${formData.viaProcess === via ? "selected" : ""}`}
                                                onClick={() => handleSelect("viaProcess", via)}
                                            >
                                                {via}
                                            </button>
                                        ))}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Castellated Holes
                                        <span className="info-tooltip">
                                            <span className="tooltip-icon">ℹ</span>
                                            <span className="tooltip-text">Select the castellated holes for your PCB.</span>
                                        </span>
                                    </td>
                                    <td>
                                        {["None", "Top", "Bottom", "Both"].map((holes) => (
                                            <button
                                                key={holes}
                                                className={`pcb-button ${formData.castellatedHoles === holes ? "selected" : ""}`}
                                                onClick={() => handleSelect("castellatedHoles", holes)}
                                            >
                                                {holes}
                                            </button>
                                        ))}
                                    </td>
                                </tr>
                                <tr>
                                    <td>PTH Copper Thickness
                                        <span className="info-tooltip">
                                            <span className="tooltip-icon">ℹ</span>
                                            <span className="tooltip-text">Select the plated through-hole copper thickness for your PCB.</span>
                                        </span>
                                    </td>
                                    <td>
                                        {["20µm", "35µm", "70µm", "105µm", "140µm"].map((thickness) => (
                                            <button
                                                key={thickness}
                                                className={`pcb-button ${formData.pthCopperThickness === thickness ? "selected" : ""}`}
                                                onClick={() => handleSelect("pthCopperThickness", thickness)}
                                            >
                                                {thickness}
                                            </button>
                                        ))}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                )
            }
            <div className="expand-section">
                <h3>Advanced Options</h3>
                <button className="expand-button" onClick={() => setExpanded2(!expanded2)}>
                    {expanded2 ? "Collapse ▲" : "Expand ▼"}
                </button>
            </div>
            {
                expanded2 && (
                    <div className="advanced-options">
                        <table className="pcb-table">
                            <tbody>
                                <tr>
                                    <td>Special Techniques
                                        <span className="info-tooltip">
                                            <span className="tooltip-icon">ℹ</span>
                                            <span className="tooltip-text">Select any special techniques you need for your PCB.</span>
                                        </span>
                                    </td>
                                    <td>
                                        {["Edge Plating", 'Carbon Ink', "Serial Number", "Countersink Hole/Step Hole", "Press-fit hole", "CTI≥600V", "Chamfering"].map((technique) => (
                                            <button
                                                key={technique}
                                                className={`pcb-button ${formData.specialTechnique.includes(technique) ? "selected" : ""}`}
                                                onClick={() => handleMultiSelect("specialTechnique", technique)}
                                            >
                                                {technique}
                                            </button>
                                        ))}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Outline Tolerance
                                        <span className="info-tooltip">
                                            <span className="tooltip-icon">ℹ</span>
                                            <span className="tooltip-text">Select the board outline tolerance for your PCB.</span>
                                        </span>
                                    </td>
                                    <td>
                                        {["±0.2mm(Regular)", "±0.1mm(Precision)"].map((tolerance) => (
                                            <button
                                                key={tolerance}
                                                className={`pcb-button ${formData.boardOutlineTolerance === tolerance ? "selected" : ""}`}
                                                onClick={() => handleSelect("boardOutlineTolerance", tolerance)}
                                            >
                                                {tolerance}
                                            </button>
                                        ))}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Paper between PCBs
                                        <span className="info-tooltip">
                                            <span className="tooltip-icon">ℹ</span>
                                            <span className="tooltip-text">Select whether you need paper between PCBs.</span>
                                        </span>
                                    </td>
                                    <td>
                                        {["No", "Yes"].map((paper) => (
                                            <button
                                                key={paper}
                                                className={`pcb-button ${formData.paperBetweenPCBs === paper ? "selected" : ""}`}
                                                onClick={() => handleSelect("paperBetweenPCBs", paper)}
                                            >
                                                {paper}
                                            </button>
                                        ))}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Test Method
                                        <span className="info-tooltip">
                                            <span className="tooltip-icon">ℹ</span>
                                            <span className="tooltip-text">Select the test method for your PCB.</span>
                                        </span>
                                    </td>
                                    <td>
                                        {["Flying Probe Test", "Fixture Test"].map((test) => (
                                            <button
                                                key={test}
                                                className={`pcb-button ${formData.testMethod === test ? "selected" : ""}`}
                                                onClick={() => handleSelect("testMethod", test)}
                                            >
                                                {test}
                                            </button>
                                        ))}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Test Report
                                        <span className="info-tooltip">
                                            <span className="tooltip-icon">ℹ</span>
                                            <span className="tooltip-text">Select the test reports you need for your PCB.</span>
                                        </span>
                                    </td>
                                    <td>{[
                                        "Electric Test", "Quality Assurance Certificate", "Final Inspection", "Surface Plating Thickness Test", "Solderability Test", "Thermal Stress Test", "Microsection Measurement", "Ionic Contamination Test"
                                    ].map((report) => (
                                        <button
                                            key={report}
                                            className={`pcb-button ${formData.testReport.includes(report) ? "selected" : ""}`}
                                            onClick={() => handleMultiSelect("testReport", report)}
                                        >
                                            {report}
                                        </button>
                                    ))}</td>
                                </tr>
                                <tr><td>PCB Remark
                                    <span className="info-tooltip">
                                        <span className="tooltip-icon">ℹ</span>
                                        <span className="tooltip-text">Enter any additional requirements or remarks for your PCB.</span>
                                    </span>
                                </td>
                                    <td>
                                        <button className="remark" onClick={() => setIsEditing((prev) => !prev)}>
                                            ✎
                                        </button>

                                    </td></tr>
                                {isEditing && (<tr>
                                    <td colSpan="2">
                                        <textarea
                                            type="text"
                                            value={formData.remark}
                                            className="remark-input"
                                            placeholder="Fill in any PCB requirements to help us better understand your needs."
                                            onChange={(e) => handleSelect("remark", e.target.value)}
                                        />
                                    </td>
                                </tr>)}



                            </tbody>
                        </table>
                    </div>

                )
            }
            <div className="expand-section">
                <label>
                    <input type="checkbox" checked={showAssemblyForm} onChange={() => setShowAssemblyForm(!showAssemblyForm)} />
                    Include PCB Assembly
                </label>
            </div>
            {showAssemblyForm && <PCBAssemblyForm isInsideAdvancedPCBForm={true} />}
            <button className="submit-button">Quote Now</button>
        </div >
    );
};

export default AdvancedPCBForm;